type LocationState = {
  region: string
  location: string
  title: string
  regionPriceDisclaimer: string
  crmRegion: string
  latitude: string
  longitude: string
}

export const formatUri = (uri = '', initialLocation: LocationState | Partial<LocationState>): string | null => {
  const { region, location } = initialLocation
  const safeUri = !uri?.startsWith('/') ? `/${uri || ''}` : uri
  const locationUrl = !region || region === 'national' ? '' : `/${region}/${location}`
  // console.log('>>>> formatUri', uri, `==> ${locationUrl}${safeUri}`)
  return `${locationUrl}${safeUri}`
}
