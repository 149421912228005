import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import OutsideClickHandler from 'react-outside-click-handler'
import LoadingUI from 'components/loading'
import { SITE_REGION_MAP } from 'lib/config'
import { reportError } from 'utils/monitoring'
import { useStore } from 'hooks/locationContext'
import PopUp from './pop-up'
import Search from 'images/svg/search-icon.svg'
import styles from './topheader.module.scss'
import queryString from 'query-string'
import { Location_Location_Entry } from 'lib/generated/graphql-types'
import { locationEntries as locationEntriesQuery } from 'gql/locationEntries.gql'

interface LocationEntries {
  entries: Location_Location_Entry[] | null
}

const saveToLs = (data: Location_Location_Entry) => {
  const formattedData: { region: string; location: string | null }[] = []
  ;(data as any).forEach((region: any) => {
    const returnedObj = {
      region: SITE_REGION_MAP[region.siteId as keyof typeof SITE_REGION_MAP],
    }
    region.children.forEach((location: any) => {
      formattedData.push({
        ...returnedObj,
        ...{
          location: location.slug,
          title: location.title,
          regionPriceDisclaimer: region.regionPriceDisclaimer || location.regionPriceDisclaimer,
          crmRegion: location.crmRegion || location.title,
          latitude: region.latitude || location.latitude,
          longitude: region.longitude || location.longitude,
        },
      })
    })

    if (!region.children.length) {
      formattedData.push({
        ...returnedObj,
        ...{
          title: region.title,
          regionPriceDisclaimer: region.regionPriceDisclaimer,
        },
      } as any)
    }
  })
  localStorage.setItem('locations', JSON.stringify(formattedData))
}

export default function TopHeader(): JSX.Element {
  // useEscPopupOnEscape();

  const [showPopUpLastSeen, setShowLastSeen] = useState(false)
  const [, setOutsideClick] = useState(false)

  const [showMore, setShowMore] = useState(false)
  // const {loading, error, data: allLocationsFromBE} = useQuery(QUERY_LOCATIONS);
  const { error, loading, data } = useQuery<LocationEntries>(locationEntriesQuery)
  const [store, setStore] = useStore()
  const router = useRouter()
  const entries = data?.entries as Location_Location_Entry[]

  function showSearch() {
    document.querySelectorAll('.--search-top-js').forEach((e) => {
      e.classList.add('--swap-search-menu-right')
    })

    document.querySelector('.--input-js-wrap').classList.add('--swap-search-input-wrap')
    document.querySelector('.--input-js').classList.add('--swap-search-input-right')
  }

  function hideSearch() {
    document.querySelectorAll('.--search-top-js').forEach((e) => {
      e.classList.remove('--swap-search-menu-right')
    })

    document.querySelector('.--input-js-wrap').classList.remove('--swap-search-input-wrap')
    document.querySelector('.--input-js').classList.remove('--swap-search-input-right')
  }

  const isEmptyValue = (val: any) => {
    return val === undefined || val === null || val === ''
  }

  function handleChange(newValue: any) {
    setShowMore(newValue)
  }

  function toggleScroll() {
    if (!showMore) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
  }

  let route = router.route.split('?')[0].split('/')
  route.shift()

  const asPath = router.asPath.split('?')[0].split('/')
  asPath.shift()
  if (route.includes('[id]')) {
    route = route.filter((item) => item !== '[id]')
    asPath.pop()
  }

  const returned = asPath
    .filter(function (obj) {
      return route.indexOf(obj) == -1
    })
    .filter((item) => item)

  const reg = !Object.values(SITE_REGION_MAP).includes(returned[0]) ? null : returned[0]
  const loc = returned[1]

  const utmContainer: any = {}
  const acceptableParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_agid', 'device', 'placement', 'gclid']
  for (const key in router.query) {
    if (acceptableParams.includes(key)) {
      utmContainer[key] = router.query[key]
    }
  }
  const urlParams = queryString.stringify(utmContainer)

  useEffect(() => {
    if (loc === undefined || loc === null || loc === '') setShowMore(true)

    if (typeof window !== 'undefined') {
      const cookieLoc = localStorage.getItem('selectedLocation')
      const cookieRegion = localStorage.getItem('selectedRegion')

      if (isEmptyValue(loc) && !isEmptyValue(cookieLoc) && !isEmptyValue(cookieLoc)) {
        setShowMore(false)
        setStore({
          ...store,
          initialLocation: {
            region: cookieRegion,
            location: cookieLoc,
          },
        })

        const regionalUrl = `/${cookieRegion}/${cookieLoc}${urlParams ? '?' + urlParams : ''}`
        // console.log('>>> ROUTER PUSH', regionalUrl)
        router.push(regionalUrl)
      }
    }
  }, [reg, loc, urlParams])

  const dataLoc = React.useMemo(() => {
    return {
      region: reg,
      location: loc,
    }
  }, [loc, reg])

  useEffect(() => {
    let titleLoc = ''
    let crmRegion = ''
    let latitude = ''
    let longitude = ''
    let locations = [] as Location_Location_Entry[]
    let priceDisclaimer = returned[2]
    const dataLoc2 = { ...dataLoc }

    if (Object.keys(dataLoc).length > 0 && reg) {
      data && saveToLs(entries as any)
      if (typeof window !== 'undefined') {
        locations = JSON.parse(localStorage.getItem('locations'))
        // console.log("locations", locations);
        if (locations) {
          const titleEntry = loc
            ? locations.find((item: any) => item.region === reg && item.location === loc)
            : locations.find((item: any) => item.region === reg) || locations[0]
          // console.log("titleEntry", titleEntry);
          titleLoc = titleEntry?.title
          priceDisclaimer = titleEntry?.regionPriceDisclaimer
          crmRegion = titleEntry?.crmRegion
          latitude = titleEntry?.latitude
          longitude = titleEntry?.longitude
        }
      }

      // console.log('titleLoc', !!(titleLoc && Object.keys(dataLoc).length !== 0))
      if (titleLoc && Object.keys(dataLoc).length !== 0) {
        ;(dataLoc2 as any).title = titleLoc
        ;(dataLoc2 as any).regionPriceDisclaimer = priceDisclaimer
        ;(dataLoc2 as any).crmRegion = crmRegion
        ;(dataLoc2 as any).latitude = latitude
        ;(dataLoc2 as any).longitude = longitude

        setStore({ ...store, initialLocation: dataLoc2 })
      }
    }
  }, [data, dataLoc, loc, reg])

  if (loading) return <LoadingUI />
  if (error) reportError(error)
  if (!data) return null

  const recentlyArray = (store: any) => {
    if (localStorage.getItem('recently')) {
      return JSON.parse(localStorage.getItem('recently'))
    } else {
      return []
    }
  }

  return (
    <header className={styles['top-header'] + ' --top-header'}>
      <div className="container">
        <div className={styles['top-header__wrap']}>
          <div className={styles['top-header__left-block']}>
            {recentlyArray(store.changeRecently).length > 0 && (
              <>
                <span>Recently viewed</span>
                <ul className={styles['top-header__left-block-list']}>
                  {recentlyArray(store.changeRecently).map((item: any, index: number) => {
                    return (
                      <li key={item.title + item.active_type + index} className="yellow-vertical-line">
                        <Link href={item.url}>
                          <a>
                            {item.title} {item.active_type}
                          </a>
                        </Link>
                      </li>
                    )
                  })}
                </ul>

                <div className={styles['last-seen']}>
                  <div className={styles['last-seen__points']} onClick={setShowLastSeen as any}>
                    ...
                  </div>

                  {showPopUpLastSeen && (
                    <OutsideClickHandler
                      onOutsideClick={(e) => {
                        setOutsideClick(e.target as any)
                        setShowLastSeen(false)
                      }}
                    >
                      <div className={styles['last-seen__pop-up-menu'] + ' --pop-up-js'}>
                        <ul>
                          {recentlyArray(store.changeRecently).map((item: any, index: number) => {
                            if (index > 1) {
                              return (
                                <li
                                  key={item.title + item.active_type}
                                  onClick={() => {
                                    setShowLastSeen(false)
                                  }}
                                >
                                  <Link href={item.url}>
                                    <a>
                                      {item.title} {item.active_type}
                                    </a>
                                  </Link>
                                </li>
                              )
                            }
                          })}
                        </ul>
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
              </>
            )}
          </div>

          <div className={styles['top-header__right-block']}>
            <div className={styles['top-header__city'] + ' --search-top-js'}>
              <span className={styles['top-header__city-title']}>Build in</span>
              <span
                className={styles['top-header__city-name']}
                onClick={() => {
                  setShowMore(!showMore)
                  toggleScroll()
                }}
              >
                {store.initialLocation.title}
              </span>
            </div>
            <div className={styles['top-header__language'] + ' --search-top-js'}>
              <ul>
                <li>Mandarin 中文</li>
                <li>Hindi 中文</li>
              </ul>
            </div>
            <div className={styles['top-header__search'] + ' yellow-vertical-line --search-top-js'} onClick={showSearch}>
              <Search />
            </div>
            <OutsideClickHandler onOutsideClick={hideSearch}>
              <div className={styles['top-header__search-input-wrap'] + ' --input-js-wrap'}>
                <input placeholder="| Search" className={styles['top-header__search-input'] + ' --input-js'} />
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      </div>
      <div className={showMore ? styles['top-header__pop-up-overlay'] : ''}>
        {showMore && (
          <OutsideClickHandler
            onOutsideClick={() => {
              if (loc === undefined || loc === null || loc === '') return
              setShowMore(false)
              toggleScroll()
            }}
          >
            <PopUp closePopUp={setShowMore} locations={entries} />
          </OutsideClickHandler>
        )}
      </div>
    </header>
  )
}
