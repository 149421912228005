const LOCATIONS_BY_REGION: any = Object.freeze({
  vic: {
    primaryLocationSlug: 'melbourne',
    longName: 'Victoria'
  },
  qld: {
    primaryLocationSlug: 'brisbane-coast',
    longName: 'Queensland'
  },
  sa: {
    primaryLocationSlug: 'adelaide',
    longName: 'South Australia'
  },
  national: {
    primaryLocationSlug: '',
    longName: ''
  }
});

export default LOCATIONS_BY_REGION;
