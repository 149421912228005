const imgixBaseUrl = (process.env.NEXT_PUBLIC_IMGIX_URL || "https://assets-prod.abchomes.com.au").replace(/\/$/, '');

export const getImgixUrl = (src: string, width: number, height: number, fit: string = "crop"): string => {
  const url = new URL(src, imgixBaseUrl);
  url.searchParams.set("fit", fit);
  url.searchParams.set("w", `${width}`);
  url.searchParams.set("h", `${height}`);

  return url.href;
};

