import { DEFAULT_LOCATIONS } from 'lib/config'
import { atom } from 'recoil'
import { ProductsServiceStateModel } from 'types/models/products-service-state.model'

type LocationState = {
  region: string
  location: string
  title: string
  regionPriceDisclaimer: string
  crmRegion: string
  latitude: string
  longitude: string
}

type LocationStore = {
  initialLocation: LocationState | Partial<LocationState>
  changeRecently: number
  allLocations: LocationState[]
}

type HomeDesignsFacadeState = {
  externalId: string
  price: number
}

let localStorageStore = null
try {
  localStorageStore = JSON.parse(localStorage.getItem('abc-location') ?? '')
} catch (e) {}

const initialLocation = localStorageStore ? localStorageStore : DEFAULT_LOCATIONS.vic
// console.log('>>> addedPriceState.initialLocation', localStorageStore ? 'localStorage' : initialLocation)

//initial value and reducer to current location
export const initialStore: LocationStore = {
  initialLocation,
  changeRecently: 0,
  allLocations: [],
}

export const LocationState = atom({
  key: 'LocationState',
  default: initialStore,
})

export const homeDesignsTotalAddedPriceState = atom({
  key: 'homeDesignsTotalAddedPriceState',
  default: 0,
})

export const houseLandAddedPriceState = atom({
  key: 'houseLandAddedPriceState',
  default: 0,
})

export const homeDesignsFacadeState = atom<HomeDesignsFacadeState>({
  key: 'homeDesignsFacadeState',
  default: {
    externalId: '',
    price: 0,
  },
})

export const homeDesignsSelectedOptionsState = atom({
  key: 'homeDesignsSelectedOptionsState',
  default: [],
})

export const homeDesignsState = atom({
  key: 'homeDesignsState',
  default: new ProductsServiceStateModel(),
})
export const houseAndLandState = atom({
  key: 'houseAndLandState',
  default: new ProductsServiceStateModel(),
})
export const displayHomesState = atom({
  key: 'displayHomesState',
  default: new ProductsServiceStateModel(),
})

export const ProductsServiceState = atom({
  key: 'ProductsServiceState',
  default: new ProductsServiceStateModel(),
})

export const ProductServiceState = atom({
  key: 'ProductServiceState',
  default: {},
})
