import '../styles/reset.scss'
import '../styles/fonts.scss'
import '../styles/globals.scss'
import "../styles/dynamics-form.scss"
import "../styles/forms/forms.scss"
import "../styles/filters/filters.scss"
import 'react-sliding-pane/dist/react-sliding-pane.css'
import { RecoilRoot } from 'recoil'
import storeUTMData from 'utils/storeUTMData'
import { anchorJumpLinks } from 'utils/anchor-jump-links'
import { useApollo } from '../lib/withApollo'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import '../styles/nprogress.scss'
import { AppProps } from 'next/dist/shared/lib/router/router'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { LocationState } from 'lib/recoilStates/addedPriceState'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function AbcApp({ Component: PageComponent, pageProps }: AppProps): JSX.Element {
  const apolloClient = useApollo(pageProps)
  const router = useRouter()
  const { region, location } = router?.query
  storeUTMData()
  anchorJumpLinks()
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })
    }

  }, [])

  const initializeRecoil = ({ set }: any) => {
    // set initial location from route path
    const { region, location } = router.query
    // console.log('>>> APP ROUTER', router.query, router.asPath)
    if (!router || !region || region === 'national') return

    set(LocationState, {
      initialLocation: { region, location },
      changeRecently: 0,
      allLocations: [],
    })
  }

  return (
    <RecoilRoot initializeState={initializeRecoil}>
      <ApolloProvider client={apolloClient}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    </RecoilRoot>
  )
}

export default AbcApp
