export const URI = ''

export const SITE_REGION_MAP = {
  1: 'vic',
  2: 'nsw',
  3: 'qld',
  4: 'sa',
  5: 'national',
}

export const RECENTY_VIEWED_NAME = 'recently'

export const DEFAULT_LOCATIONS = Object.freeze({
  vic: {
    region: 'vic',
    location: 'melbourne',
    title: 'Melbourne',
    regionPriceDisclaimer: '',
    crmRegion: 'Melbourne',
    latitude: '-37.81357929',
    longitude: '144.96294662',
  },
  sa: {
    region: 'sa',
    location: 'adelaide',
    title: '',
    regionPriceDisclaimer: '',
    crmRegion: '',
    latitude: '',
    longitude: '',
  },
  qld: {
    region: 'qld',
    location: 'brisbane-coast',
    title: '',
    regionPriceDisclaimer: '',
    crmRegion: '',
    latitude: '',
    longitude: '',
  },
})
