/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import styles from './mobile-nav.module.scss'
import React, { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useStore } from 'hooks/locationContext'
import PopUp from 'components/top-header/pop-up'
import { gql, useQuery } from '@apollo/client'
import LoadingUI from 'components/loading'
import { reportError } from 'utils/monitoring'
import getUri from 'lib/getUri'
import { Header_Button_Entry, Header_Item_Entry, Header_Phone_Entry } from 'lib/generated/graphql-types'
import { formatUri } from 'lib/formatUri'

interface HeaderEntries {
  entries: (Header_Button_Entry | Header_Item_Entry | Header_Phone_Entry)[] | null
}

export default function MobileNav({ closePopUp }: any): JSX.Element {
  const currentLocation = useStore()[0]
  const { initialLocation: loc } = currentLocation
  const { region, location } = currentLocation.initialLocation

  const QUERY_HEADER = gql`
      query getHeaderTwo($siteHandle: [String] = "${region || 'vic'}"){
        entries(site: $siteHandle, section: "header"){
          ...on header_phone_Entry {
            phone
            typeHandle
          }
          ...on header_button_Entry{
            title
            typeHandle
            internalLink{
              uri
            }
            externalLink
          }
          ...on header_item_Entry{
            id
            parent{
              id
            }
            title
            typeHandle
            slug
            externalLink
            internalLink {
              id
              uri
            }
          }
        }
      }
  `
  const { loading, error, data } = useQuery<HeaderEntries>(QUERY_HEADER)

  const store = useStore()[0]
  const router = useRouter()
  const activeLinkClass = styles['--active']
  const [showPopUpLocation, setShowMore] = useState(false)

  if (loading) return <LoadingUI />
  if (error) reportError(error)
  if (!data) return null

  const entriesPhone = data?.entries as Header_Phone_Entry[]
  const entries = data?.entries as Header_Item_Entry[]

  const phoneNumber = (entriesPhone?.filter((entry) => entry?.phone) || [])[0]?.phone || ''

  const internalLinks = entries?.filter((entry) => entry?.parent && entry?.title?.trim().length)
  const generalLinks =
    entries
      ?.filter((entry) => !entry?.parent && entry?.title?.trim().length)
      .map((entry) => ({
        ...entry,
        internalLinks: internalLinks.filter((internalLink) => internalLink?.parent?.id === entry?.id),
      })) || []

  const linksUI = (links: any) =>
    links?.map((link: any, index: any) => {
      const url = link?.internalLink?.length ? `${getUri(store.initialLocation)}/${link.internalLink[0].uri}` : link?.externalLink
      const isExternal = url && /^https?:\/\//.test(url)
      return (
        <li key={'internalLink_' + index}>
          <Link href={url || '/'}>
            <a target={isExternal ? '_blank' : undefined} rel={isExternal ? 'noopener noreferrer' : undefined}>
              {link?.title || ''}
            </a>
          </Link>
        </li>
      )
    })

  const navUI = generalLinks?.map((link, index) => {
    if (!link?.internalLinks?.length) {
      return (
        <li key={'generalLink_' + index}>
          <Link href={`${getUri(store.initialLocation)}/${link?.externalLink || '/'}`}>
            <a>{link?.title || ''}</a>
          </Link>
        </li>
      )
    } else {
      return (
        <li key={'generalLink_' + index}>
          <a className="main-header--active" onClick={(e) => ShowSecondMenu(e)}>
            {link?.title || ''}
          </a>
        </li>
      )
    }
  })

  const ShowSecondMenu = function (e: any) {
    document.querySelector('.--first-menu-js').classList.toggle('--swap-right')
    document.querySelector('.--second-menu-js').classList.toggle('--swap-left')
    document.querySelector('.--build-menu-js').setAttribute('style',buildMenuMargin)
  }

  const ShowFirstMenu = function (e: any) {
    document.querySelector('.--first-menu-js').classList.toggle('--swap-right')
    document.querySelector('.--second-menu-js').classList.toggle('--swap-left')
    document.querySelector('.--build-menu-js').setAttribute('style','')
  }

  const showTopMenu = () => {
    document.querySelector('.--top-header').classList.remove('--d-none')
  }
  const menuItemCount = internalLinks.length
  const buildMenuMargin = menuItemCount > 6
    ? `margin-top: ${(menuItemCount - 6) * 32}px`
    : ''

  return (
    <div className={styles['mobile-nav']}>
      <div
        className={styles['mobile-nav__close']}
        onClick={() => {
          closePopUp(false)
          showTopMenu()
        }}
      />
      <div className={styles['mobile-nav__wrap']}>
        <nav className={styles['mobile-nav__navigation']}>
          <ul className={styles['mobile-nav__first-menu'] + ' --first-menu-js'}>{navUI}</ul>
          <div className={styles['mobile-nav__second-menu'] + ' --second-menu-js'}>
            <div className={styles['mobile-nav__second-menu-back']} onClick={ShowFirstMenu}>
              Back
            </div>
            <div className={styles['mobile-nav__second-menu-more']}>More</div>
            <ul>{linksUI(internalLinks)}</ul>
          </div>
        </nav>
        <div className={styles['mobile-nav__languages']}>
          <ul>
            <li>Mandarin 中文</li>
            <li>Hindi 中文</li>
          </ul>
        </div>
        <div className={styles['mobile-nav__build'] + ' --build-menu-js'}>
          <span className={styles['mobile-nav__build-in']}>Build in</span>
          <span
            className={styles['mobile-nav__build-in-city']}
            onClick={() => {
              setShowMore(!showPopUpLocation)
            }}
          >
            {store.initialLocation.title}
          </span>
        </div>
        <div className={styles['mobile-nav__call']}>
          <a href={'tel:' + phoneNumber} className={styles['mobile-nav__call-phone']}>
            {phoneNumber}
          </a>
          <Link href={formatUri(`/contact-us`, loc)}>
            <a className="yellow-button">Contact</a>
          </Link>
        </div>
      </div>

      {showPopUpLocation && <PopUp closePopUp={setShowMore} />}
    </div>
  )
}
