import React from "react-dom";
import styles from "./loading.module.scss";

export default function LoadingUI(): JSX.Element {
  return (
    <div className={styles["loader-wrapper"]}>
      <div className={styles["loader"]}></div>
      <div className={styles["section-left"]}></div>
      <div className={styles["section-right"]}></div>
    </div>
  );
}
