import { GetStaticPropsContext, GetStaticPropsResult } from 'next'
import { homePageEntry as homePageEntryQuery } from './query.gql'
import { getPageEntry, getPageSkeleton } from 'lib/pageInit'
import MainLayout from 'components/main-layout/index'
import HouseSlider from '../components/home/house-slider'
import BuildYourHome from 'components/build-your-home'
import BuildYourHomeImg from '../components/home/build-your-home-img'
import MelbourneDisplays from '../components/home/melbourne-displays'
import MainPageTopSlider from '../components/home/top-slider'
import MadeEasy from '../components/home/made-easy'
import {
  HomePage_HomePage_Entry,
  Banner_MatrixField,
  Carousel_MatrixField,
  HeaderAndText_MatrixField,
  HeaderTextAndButton_MatrixField,
  RegionHeaderAndText_MatrixField,
  TextAndBackgroundImage_MatrixField,
} from 'lib/generated/graphql-types'
import { useStore } from 'hooks/locationContext'

export type HomePageProps = {
  entry?: HomePage_HomePage_Entry
  globalSeoData: any
  headerData: any
  footerData: any
  bottomFooterData: any
}

const HomePage = ({ entry = null, globalSeoData = null, headerData, footerData, bottomFooterData }: HomePageProps): JSX.Element => {
  // Init store
  const currentLocation = useStore()[0]
  const { region } = currentLocation.initialLocation

  // Check data
  if (!entry) {
    return null
  }

  // const seomatic = entry?.seomatic
  // const seoOverrides = entry?.seoOverrides
  const banner = entry?.banner as Banner_MatrixField[]
  const headerAndText = entry?.headerAndText as HeaderAndText_MatrixField[]
  const headerText = entry?.headerAndText[0]?.header ?? null
  const regionHeaderAndText = entry?.regionHeaderAndText as RegionHeaderAndText_MatrixField[]
  const carousel = entry?.carousel as Carousel_MatrixField[]
  const textAndBackgroundImage = entry?.textAndBackgroundImage as TextAndBackgroundImage_MatrixField[]
  const headerTextAndButton = entry?.headerTextAndButton as HeaderTextAndButton_MatrixField[]

  const seo = {
    canonicalUrl: entry?.seo_canonical ?? `https://abchomes.com.au`,
    title: entry?.seo_metaTitle ?? headerText,
    description: entry?.seo_metaDescription,
    image: entry?.seo_metaImage,
    noindex: entry?.seo_noindex,
    overrides: entry?.seoOverrides,
  }

  return (
    <div>
      <MainLayout seo={seo} globalSeoData={globalSeoData} title="Home Page" headerData={headerData} footerData={footerData} bottomFooterData={bottomFooterData}>
      {/* <MainLayout seo={seomatic} metaData={seoOverrides}> */}
        <MainPageTopSlider banner={banner} />
        <MadeEasy headerAndText={headerAndText} regionHeaderAndText={regionHeaderAndText} />
        <HouseSlider carousel={carousel} />
        <BuildYourHome />
        <BuildYourHomeImg textAndBackgroundImage={textAndBackgroundImage} />
        <MelbourneDisplays headerTextAndButton={headerTextAndButton} />
      </MainLayout>
    </div>
  )
}

export const getStaticProps = async ({}: GetStaticPropsContext): Promise<GetStaticPropsResult<HomePageProps>> => {

  const { headerData, footerData, bottomFooterData } = await getPageSkeleton()
  // console.log('>>> HomePage.getStaticProps')
  const entryQuery = {
    query: homePageEntryQuery,
    variables: {
      section: 'homePage',
      siteHandle: 'national',
    },
  }

  const props = {
    headerData,
    footerData,
    bottomFooterData,
  }

  const result = await getPageEntry({ entryQuery, urlPath: '', props })

  // return result with static props revalidate setting
  return {
    ...result,
    revalidate: 300, // 5 mins
  }
}

export default HomePage
