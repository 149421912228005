/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect } from 'react'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import TopHeader from '../top-header'
import MainHeader from '../main-header'
import MainFooter, {MainFooterProps}  from '../main-footer'
import BottomFooter from '../bottom-footer'
import LOCATIONS_BY_REGION from 'lib/locationsByRegion'
import { useStore } from 'hooks/locationContext'
import { gql, useQuery } from '@apollo/client'
import { NextSeo } from 'next-seo'
import { getImgixUrl } from 'lib/getImgixUrl'
import { Copyright_GlobalSet, Footer_HeaderItems_Entry, Footer_HeaderUpItems_Entry } from 'lib/generated/graphql-types'

type MainLayoutProps = {
  children: React.ReactNode
  title?: string
  activeTab?: string
  notFound?: boolean
  headerData?: any
  footerData?: Array<Footer_HeaderItems_Entry | Footer_HeaderUpItems_Entry>
  bottomFooterData?: Copyright_GlobalSet
  seo?: any
  globalSeoData?: any
}

const MainLayout = ({ children, title = 'ABCHOMES', activeTab, notFound = false, headerData, footerData, bottomFooterData, globalSeoData, seo }: MainLayoutProps): JSX.Element => {
  // const [metaTitleSuffix, setMetaTitleSuffix] = useState<string>('')
  const redirectsQuery = gql`
    query redirects {
      globalSet(handle: "maintenance") {
        ... on maintenance_GlobalSet {
          maintenance
        }
      }
    }
  `
  const { data } = useQuery<any>(redirectsQuery)

  useEffect(() => {
    if (data?.globalSet?.maintenance) Router.push('/maintenance')
  }, [data])

  const currentLocation = useStore()[0]
  const { region = 'national' } = currentLocation.initialLocation
  const router = useRouter()
  const location = router?.query?.location

  if (router?.query?.slug === '[id]') {
    Router.push('/')
  }

  let slug = router.query.slug

  if (!router.query.id) {
    slug = ((router?.asPath || '').split('/') || [])[(router?.asPath || '').split('/').length - 1]
    if (slug === router?.query?.location) slug = 'home'
  }

  //NEW META CONSTANTS

  const metaDataByLocation = seo?.overrides?.find((item: any) => item?.location?.some((loc: any) => loc?.slug === location))
  // console.log('>>> GLOBAL SEO DATA', globalSeoData);

  //NEW META FUNCTIONS
  const getTitle = () => {
    const title = metaDataByLocation?.seoTitle ?? seo?.title ?? 'Australian Building Co.'
    const titleNoHtml = title?.replace(/<[^>]*>?/gm, '')
    const metaTitleSuffix = globalSeoData?.global__metaTitleSuffix ?? ''
    return `${titleNoHtml} ${metaTitleSuffix}`
  }

  const getDescription = () => {
    const entrySeoDescription = seo?.description && seo?.description.length > 0 ? seo?.description : null
    return (
      metaDataByLocation?.seoDescription ??
      entrySeoDescription ??
      `For ${LOCATIONS_BY_REGION?.[region]?.longName} home builders you can trust, join the many property owners who have worked with Australian Building Co. to achieve their dream home.`
    )
  }

  const getCanonical = () => {
    // console.log('metaDataByLocation?.canonicalUrl', metaDataByLocation?.canonicalUrl)
    return metaDataByLocation?.canonicalUrl ?? seo?.canonicalUrl
  }

  const getOpenGraphImage = () => {
    // 1,200 x 630 pixels
    const image =
    (metaDataByLocation?.seoImage && metaDataByLocation?.seoImage.length > 0 ? metaDataByLocation?.seoImage[0] : null) ||
    (seo?.image && seo?.image.length > 0 ? seo.image[0] : null) ||
    globalSeoData?.global__metaImage[0];

    if (!image) return null

    const imageSrc = getImgixUrl(image?.url, 1200, 630, 'crop')
    const imagesArray = [
      {
        url: imageSrc,
        width: 1200,
        height: 630,
        alt: image?.title,
      },
    ]
    // TODO: need to transform image?
    return imagesArray
  }

  const nextSeoProps = {
    title: getTitle(),
    description: getDescription(),
    canonical: getCanonical(),
    noindex: notFound || seo?.noindex,
    openGraph: {
      title: getTitle(),
      description: getDescription(),
      url: getCanonical(),
      images: getOpenGraphImage() ? getOpenGraphImage() : [],
      site_name: 'Australian Building Co.',
    },
    twitter: {
      handle: '@',
      creator: '@',
      title: getTitle(),
      description: getDescription(),
      image: getOpenGraphImage() ? getOpenGraphImage()[0].url : '',
      imageAlt: getOpenGraphImage() ? getOpenGraphImage()[0].alt : '',
      cardType: 'summary_large_image',
    },
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="facebook-domain-verification" content="rs2lg8yy795hrml075aeo0qu9ydv7y" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta property="og:type" content="website" />
        <link rel="shortcut icon" href="https://assets-prod.abchomes.com.au/favicon.png" />
      </Head>
      {nextSeoProps && <NextSeo {...nextSeoProps} />}
      <TopHeader />
      <div className="sticky-wrap">
        <div className="sticky">
          <MainHeader activeTab={activeTab} headerData={headerData} />
        </div>
        <main>{children}</main>
        <MainFooter footerData={footerData} />
        <BottomFooter {...bottomFooterData} />
        {process.env.NEXT_PUBLIC_ENV !== 'prod' && (
          <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=bcblmjqgpxrgl5jkyo8ioa" async={true} />
        )}
        <script src="https://view.situsystems.com/v2/situsystems-situview.js"></script>
        <script src="https://cdn.situsystems.com/facade/api.js"></script>
      </div>
    </>
  )
}

export default MainLayout
